import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/Tobias/Desktop/affiliate/sites/vandringsled/gatsby/node_modules/gatsby-theme-base/src/components/layout.js";
import { graphql } from "gatsby";
import Image from "../../components/Image";
export const pageQuery = graphql`
  query {
    kupoltalt: file(relativePath: { eq: "kupoltalt.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 500) {
          base64
          aspectRatio
          width
          height
          src
          srcSet
        }
      }
    }
    tunneltalt: file(relativePath: { eq: "tunneltalt.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 500) {
          base64
          aspectRatio
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`När det kommer till vilket vandring och olika tälttyper så pratar man
ofta om kupoltält och tunneltält. Precis som det låter så ser
kupoltältet ut som ett kupol, medans tunneltältet är format som en
tunnel. När du bestämmer vilket typ av tält du ska köpa finns det
flera saker du ska ta hänsyn till. Till exempel på vart du ska tälta,
hur vädret är där, hur underlaget är, hur du har tänkt transportera
dit tältet och vad för typ av komfort du vill ha.`}</p>
    <p>{`Vi kommer här nedan att beskriva lite mer ingående de olika
tälttyperna, men sammanfattningsvis kan man säga:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Tunneltält`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Känsligt för vindriktning`}</li>
          <li parentName="ul">{`Lättare`}</li>
          <li parentName="ul">{`Stora absider`}</li>
          <li parentName="ul">{`Måste fästas i marken`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Kupoltält`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Vindriktning spelar ingen roll`}</li>
          <li parentName="ul">{`Tyngre`}</li>
          <li parentName="ul">{`Bekvämare att sitta inne i tältet`}</li>
          <li parentName="ul">{`Små absider`}</li>
          <li parentName="ul">{`Behöver ej fästas i marken`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "tunneltält"
    }}>{`Tunneltält`}</h2>
    <div className="reponsive_image">
  <Image fixed={props.data.tunneltalt.childImageSharp.fixed} alt="Tvåmanna tunneltält med en stor absid utanför ingången till innertältet." title="Tvåmanna tunneltält med en stor absid utanför ingången till innertältet." rowOne="Ett tvåmanna tunneltält med en stor absid. Absiden är utanför ingången till intertältet och har sedan två ingångar till sig utifrån." rowTwo="" rowThree="" mdxType="Image" />
    </div>
    <h3 {...{
      "id": "innertält-och-yttertält"
    }}>{`Innertält och yttertält`}</h3>
    <p>{`I regel består ett tunneltält av ett innertält och ett yttertält. Det
finns flera anledningar till att man har ett innertält. En av dessa är
att man i innertältet har en mer ”skyddad” atmosfär där man kan stänga
ute mygg. Tillskillnad från yttertältet som är helt tätt då det ska
skydda från regn och vind, så andas innertältet och kan ventilera ut
fuktig luft och ventilera in friskare luft. En annan anledning till
innertält är att det på tältduken kan bildas kondens i form av
vattendroppar. Detta sker då den varma luften som lämnar våra kroppar
träffar en kall yta. Innertältets yta är i det här fallet är i regel
inte så kallt så att det bidas kondens. Istället är den kalla ytan
yttertältet där det inte gör lika mycket att det bildas kondens då man
ändå ligger skyddad i innertältet.`}</p>
    <h3 {...{
      "id": "absider"
    }}>{`Absider`}</h3>
    <p>{`Vanligtvis så har men ganska stora absider i ett tunneltält. Detta då
konstruktionen tillåter tillverkarna att helt enkelt endast göra en
lite längre tunnel och på så sätt få en större absid eller sovdel.
Jämför man med ett kupoltält så måste hela tältets omkrets och höjd
ökas om man vill ha större absid, något som leder till märkbart högre
vikt.`}</p>
    <p>{`Vanligtvis sover man i tunnelns riktning, och ibland finns ingångar
och absider i båda ändarna, och ibland saknar fotändan ingång och
absid och smalnar istället av i den änden för att spara vikt och
volym.`}</p>
    <h3 {...{
      "id": "konstruktion"
    }}>{`Konstruktion`}</h3>
    <p>{`Tältdukarna i ett tunneltält spänns i regel upp med 2-3 bågar som går
över ”tunneln” i ena riktningen. Dock krävs även tältpinnar och snören
för för att spänna upp duken och tältet i andra riktningen och
tunneltält kräver därför att man fäster tältet i marken. Detta kan
vara bra att tänka på om man t.ex. ska tälta på berg eller väldigt
stenig yta där det är svårt att få ner tältpinnar. Då kan det vara bra
att fundera på om man istället ska satsa på ett kupoltält, alternativt
se om det finns möjlighet att fästa snörena i träd eller spänna upp
tältet med hjälp av lite tyngre stenar.`}</p>
    <p>{`Då det inte krävs så många bågar för att hålla uppe tältet är
tunneltält i regel lättare än kupoltält.`}</p>
    <p>{`Tunneltältets konstruktion gör att de är känsliga för vind från sidan
och när man sätter upp tältet ska man se till att vinden kommer
framifrån eller bakifrån. Detta kan vara lurigt i t.ex. fjällen där
vinden ofta kan ändra riktning snabbt och man kan vakna upp med vinden
från sidan vilket i värsta fall kan leda till att tältet går sönder,
även om det ofta endast bara leder till att yttertältet trycks in mot
innertältet och man kan få in lite extra fukt.`}</p>
    <h2 {...{
      "id": "kupoltält"
    }}>{`Kupoltält`}</h2>
    <div className="reponsive_image">
  <Image fixed={props.data.kupoltalt.childImageSharp.fixed} alt="Tvåmanna kupoltält med två absider och två ingångar" title="Tvåmanna kupoltält med två absider och två ingångar" rowOne="Ett tvåmanna kupoltält med en liten absid utanför innertältet. På baksidan finns en likadan ingång med en lika stor absid." rowTwo="" rowThree="" mdxType="Image" />
    </div>
    <h3 {...{
      "id": "innertält-och-yttertält-1"
    }}>{`Innertält och yttertält`}</h3>
    <p>{`Precis som med tunneltält så består även kupoltält i regel av
innertält samt yttertält. Detta då de, precis som i tunneltält, har
som syfte att förhindra fukt samt stänga ute kryp från sovdelen.`}</p>
    <h3 {...{
      "id": "absider-1"
    }}>{`Absider`}</h3>
    <p>{`Tillskillnad från tunneltält så har i regel kupoltält relativt små
absider. Detta då konstruktionen gör att både vikt och volym måste
ökas markant för att få större volymer. Detta tillskillnad från
tunneltält där det krävs ganska liten ökning av volym och vikt för att
få stora absider.`}</p>
    <p>{`De flesta kupoltält har dock någon form av absid, eller absider.
Beroende på konstruktionen så har man ibland två ingångar med en absid
vid varje ingång, eller en ingång och då bara en absid. De flesta
kupoltält har tillräckligt stora absider för att kunna förvara
ryggsäck och kängor medans man sover. Däremot är det ofta inte
tillräckligt stora för att sitta i och laga mat vid t.ex. regn.`}</p>
    <h3 {...{
      "id": "konstruktion-1"
    }}>{`Konstruktion`}</h3>
    <p>{`I regel spänns kupoltält upp av tre stycken bågar som korsars i taket
och bildar en kupol. Dessa bågar träs i regel igenom yttertältet och
när de är på plats så spänns tältet upp av sig självt utan fäste i
marken. Innertältet hängs sedan upp i yttertältet på insidan vilket
man kan göra i lugn och ro skyddat om det skulle regna ute.`}</p>
    <p>{`Spänningen i bågarna och yttertältets konstruktion gör att kupoltält
blir helt självstagande. Detta betyder att de inte behöver fästas i
marken för att behålla sin form. Därför kan kupoltält vara att föredra
i skärgården då man ofta tältar direkt på berg utan möjlighet att
fästa i marken. Däremot kan det ändå vara en bra idé att fästa tältet
för att förhindra att vind flyttar på det.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      